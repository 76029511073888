import axios from "@/api/axios";
//import store from '@/store'
import _ from "lodash";

// 메인 가지고 오기
const get_main = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/main",
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_main", err);
    result(false, err);
  }
};

const get_salegoodslist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/main/salegoodslist",
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_main", err);
    result(false, err);
  }
};

const get_saleshoplist = async (param, result) => {
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/main/saleshoplist",
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    params: param,
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data);
      } else {
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_main", err);
    result(false, err);
  }
};

// 인스타그램 가지고 오기
const get_instagram = async (token, result) => {
  let finalData = {
    data: [],
  };

  const options = {
    method: "GET",
    url: "https://graph.instagram.com/me/media",
    params: {
      fields:
        "caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username",
      access_token: token,
      limit: 10,
    },
  };
  try {
    await axios(options).then((response) => {
      result(true, response.data);
    });
  } catch (err) {
    console.error("get_instagram", err);
    result(false, err);
  }
};

export default {
  get_main,
  get_instagram,
  get_salegoodslist,
  get_saleshoplist,
};
